import React from "react"
import Products from "components/Shop/Products"
import Header from "components/Header"
import Footer from "components/Footer"
import { Link, graphql } from "gatsby"
import Sticky from 'react-stickynode';
import KarateFont from "../fonts/fonts"

const ProductsPage = ({ data }) => {
  return (
    <div>
      <KarateFont/>
      <Sticky innerZ={1}> 
        <Header showCartIcon={true}/>
      </Sticky>
      <Products data={data} />
      <Footer/>
    </div>
  )
}

export default ProductsPage

export const products = graphql`
  query productsQuery {
    allShopifyProduct(sort: { fields: title }) {
      edges {
        node {
          id
          title
          shopifyId
          description
          handle
          availableForSale
          priceRange {
            minVariantPrice {
              amount
            }
          }
          images {
            id
            originalSrc
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
