import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const Products = ({ data }) => {
  return (
    <div class="container" style={{paddingBottom: "60px"}}>
      <div class="row">
        {data.allShopifyProduct.edges.map(({ node }) => (
          <div class="col-md-4" style={{zIndex: "0"}}>
            <Link to={`/product/${node.handle}`}>
              <Img
                fluid={node.images[0].localFile.childImageSharp.fluid}
                style={{ maxHeight: "350px", maxWidth: "350px", display: "block", marginLeft: "auto", marginRight: "auto"}}
              />
            </Link>
            <div>
              <h5 class="text-center" style={{marginTop: "8px", fontFamily: "Karate"}}>{node.title}</h5>
              <hr class="mt-2 mb-2" style={{width: "50%", backgroundColor: "black"}}/>
              {node.availableForSale ? 
                  <p class="text-center font-weight-bold" style={{marginBottom: "20px"}}>
                  €{node.priceRange.minVariantPrice.amount}
                </p> :
                  <p class="text-center font-weight-bold" style={{marginBottom: "20px"}}>
                  SOLD OUT
                </p>
            }
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Products
